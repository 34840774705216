<template>
    <app-data-table
        csv-filename="Boxes"
        :headers="getTableColumns"
        :view-new-button="false"
        :view-edit-button="false"
        :view-tour-button="false"
        :view-show-filter="false"
        :view-show-button="false"
        :items="operations"
        :manager="'boxes'"
        :sort-by="['name']"
        :sort-desc="[false, true]"
        multi-sort
    >
        <template v-slot:[`item.open_to`]="{ item }">
            {{item.open_to.firstName}}
        </template>
        <template v-slot:[`item.close_by`]="{ item }">
            {{item.close_by?item.close_by.firstName: '---' }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
            {{new Date(item.created_at).toLocaleDateString() +' '+ new Date(item.created_at).toLocaleTimeString() }}
        </template>
    </app-data-table>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'ListBoxOperation',
  data () {
  },
  computed: {
    ...mapState('boxes', ['operations']),
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.actions.open_to'),
          value: 'open_to',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.date'),
          value: 'created_at',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.boxes.init'),
          value: 'open_money',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.close_by'),
          value: 'close_by',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.boxes.final'),
          value: 'close_money',
          select_filter: true
        }
        // {
        //   text: this.$vuetify.lang.t('$vuetify.actions.actions'),
        //   value: 'actions',
        //   sortable: false
        // }
      ]
    }
  }
}
</script>

<style scoped>

</style>
